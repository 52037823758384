/* ADAPTABILIDAD ///////////////////////////////////////////////// */
/* antes de 990px */
@media only screen and (max-width: 990px) {

    /* PAGINA HOMEE //////////////////////////////////////// */
    .imagen-desktop {
        display: none !important; /* Oculta la imagen de escritorio en móviles */
    }

    .imagen-mobile {
        display: block !important; /* Muestra la imagen móvil */
    }

    /* mercadopago adaptable imagen */
    .mpImagen{
        width: 200px !important;
    }

    .logoContendorPrincipal{
        width: 250px !important;
    }

    .imgVertical{ height: 450px !important; margin-bottom: 30px !important; margin-top: 30px;}
    .contenedorANALITICA, .contenedorPAGA, .contenedorHomeInicial, .contenedorPrivacidad, .contenedorTerminos, .contenedorCookies, .contenedorComoFuncionaInicial, .contenedorContactoPagina{ padding: 100px 5px 100px 5px !important; }
    .claseIframeVideos, .claseIframeComoFunciona{
        width: 100% !important; 
        height: 193px !important;
    }
    .subContenedorPrincipal{
        font-size: 15px !important;
        letter-spacing: 5px !important;
    }
    
    .contenedorTarjetasUnoYdos{ border-bottom-style: solid; }
    
    .primerContMark{margin-top: 10px !important;}

    /* .textoCarrusel {  margin-top: -70px !important; } */

    .carousel-item { height: 25rem !important;}
    .carousel-caption { bottom: 5rem !important;  }
    
    .carousel{margin-bottom: 0px !important;}
    .imagenCarrusel{height: 320px !important; width: 268 !important; background-size: cover;}

    .contenedorInternoContacto{ width: 100% !important; }

    .videoComoFuncionanVideos{
        height: 150px !important;
      }
    /* PAGINA HOMEE //////////////////////////////////////// */

    /* */
    #contenedorTipologias .carousel-item {height: 100% !important;}

    .claseVideoHome{
        width: 100% !important;
        height: 200px !important;
        
    }

    .claseVideosPartido{
        width: 100% !important;
        height: 100% !important;
    }

    .tituloPaginas{
        display: none !important;
    }

    /* .inputBusquedaHome, .inputFiltroHome{
        width: 120px !important;
    } */

    .videoEnvVerVideos{
        height: 210px !important;
    }

    .videoIndividual{
        height: 220px !important;
    }

    .tituloVideoIndividual{
        display: block !important;
    }

    .bordeAnaliticas{
        width: 100% !important;
    }


    /* Grabarr */
.imagenEquipos{
    width: 70px !important;
}

.partido-01{
    grid-template-columns: 1fr !important; 
}

.imagen-equipo-01,
.imagen-equipo-02 {
    width: 10% !important;
    height: 10% !important;
}

.nombre-equipo-01,
.nombre-equipo-02 {
    font-size: 16px !important;
}
}
/* ADAPTABILIDAD ///////////////////////////////////////////////// */


/* imagen home */
.imagen-mobile {
    display: none; /* Oculta la imagen móvil por defecto */
  }
  
 



.contenedorAlerta{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}




/* Página contacto */
.imgVertical{ height: 650px; border-radius: 25px; box-shadow: 2px 2px 5px #393939; }
/* PÁGINA HOME /////////////////////////////////////////////////////////////////////// */
.claseIframeVideos{
    width: 100%; 
    height: 666px;
}
.claseIframeComoFunciona{
    width: 100%; 
    height: 357px;
}
/* clase para oscurecer imágenes de carruseles */
.imagen-oscura::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    z-index: 1;
  }

  .videoComoFuncionanVideos{
    width: 100%;
    height: 300px;
  }
  

/* Contendor de imagen y texto principal de home */
.contenedorHomeInicial{
    /* background-image: url('../../../public/imagenes/webGlobal/comolavioh.jpg'); */
    background-image: url('../../../public/imagenes/webGlobal/clvoficial.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 220px 0px 220px 0px; */
     padding: 420px 0px 420px 0px;
}
/* Contendor de imagen y texto principal de como funciona */
.contenedorFAQPRINCIPAL{
    background-image: url('../../../public/imagenes/webGlobal/faq.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 220px 0px 220px 0px;
}
/* Contendor de imagen y texto principal de como funciona */
.contenedorANALITICA{
    background-image: url('../../../public/imagenes/webGlobal/analitica.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 220px 0px 220px 0px;
}
/* Contendor de imagen y texto principal PAGA  */
.contenedorPAGA{
    background-image: url('../../../public/imagenes/webGlobal/paga.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 220px 0px 220px 0px;
}
/* Contendor de imagen y texto principal de contacto */
.contenedorContactoPagina{
    background-image: url('../../../public/imagenes/webGlobal/contacto.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 220px 0px 220px 0px;
}
/* Contendor de imagen y texto privacidad */
.contenedorPrivacidad{
    background-image: url('../../../public/imagenes/webGlobal/privacidad.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 220px 0px 220px 0px;
}
/* Contendor de imagen y texto terminos */
.contenedorTerminos{
    background-image: url('../../../public/imagenes/webGlobal/terminos.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 220px 0px 220px 0px;
}
/* Contendor de imagen y texto terminos */
.contenedorCookies{
    background-image: url('../../../public/imagenes/webGlobal/cookies.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 220px 0px 220px 0px;
}
/* Contendor de imagen y texto principal de como funciona */
.contenedorVERVIDEOS{
    background-image: url("https://alianz.esmio.co/wp-content/uploads/2025/01/1-6.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 0px 120px 0px;
}

/* Clase para el logo del contendor de home principal */
.logoContendorPrincipal{
    width: 500px;
    margin: 0px 0px 30px 0px;
}

.contenedorCarrusel{
    height: 600px;
    display: flex;
    align-items: center !important;
}

.subContenedorPrincipal{
    color: #dad8d8;
    font-size: 25px;
    letter-spacing: 5px;
    font-weight: 400 !important;
}



/* Tarjetas de contenedor 2 home */
.contenedorTarjetas{
    border-right-style: solid;
    border-width: 1px;
    border-color: rgb(225, 225, 225);
}

.contenedorTarjetas p{
    color: rgb(156, 156, 156);
    font-weight: 400 !important;
    font-size: 13px;
}

.contenedorTarjetas h4{
    color: rgb(24, 24, 24);
    font-weight: 600 !important;
    font-size: 15px;
}

.contenedorTarjetas i{ font-size: 35px; }


/* * MARKETING CONTENT - clases bootstrap para contenedor de marketing //////////
-------------------------------------------------- */ 

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}
/* rtl:end:ignore */

/* Imagen publicidad */
.imagen-hover-123 {
    transition: transform 0.6s ease-in-out;
  }
  
  .imagen-hover-123:hover {
    animation: reboteDiagonal 1s ease-in-out forwards; /* Suavizamos la animación */
  }
  
  @keyframes reboteDiagonal {
    0% {
      transform: translate(0, 0); /* Posición original */
    }
    25% {
      transform: translate(-10px, -10px); /* Desplazamiento pequeño hacia arriba y a la izquierda */
    }
    50% {
      transform: translate(10px, 10px); /* Mueve hacia abajo y a la derecha */
    }
    75% {
      transform: translate(-10px, -10px); /* Vuelve a la posición superior izquierda */
    }
    100% {
      transform: translate(0, 0); /* Vuelve a la posición original */
    }
  }
   
/*  */
/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
/* rtl:begin:remove */
.featurette-heading { letter-spacing: -.05rem; }

/* rtl:end:remove */

.miImagenMark{ width: 100%; border-radius: 4%;}
.primerContMark{margin-top: 80px;}
/* * MARKETING CONTENT - clases bootstrap para contenedor de marketing ///////////



/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel { margin-bottom: 4rem; }
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
    bottom: 3rem;
    z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item { height: 45rem; }

  /* RESPONSIVE CSS
-------------------------------------------------- */

/* Contenedor cómo funciona clases bootstrap /// */
.feature-icon {
    width: 4rem;
    height: 4rem;
    border-radius: .75rem;
}

.icon-square {
    width: 3rem;
    height: 3rem;
    border-radius: .75rem;
}

.text-shadow-1 { text-shadow: 0 .125rem .25rem rgba(0, 0, 0, .25); }
.text-shadow-2 { text-shadow: 0 .25rem .5rem rgba(0, 0, 0, .25); }
.text-shadow-3 { text-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .25); }

.card-cover {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.feature-icon-small {
    width: 3rem;
    height: 3rem;
}
/* Contenedor cómo funciona clases bootstrap /// */

.iconComoFunciona{
    font-size: 25px;
    color: white;
}

.token{ width: 50px;}

.contenedorFAQ{ background-color: #395544; }

.accordion-button:not(.collapsed) {
    color: white;
    background-color: #408059;
}

.contenedorContacto{  background-color: #efefef; }

.contenedorInternoContacto{
    background-color: #223531;
    width: 80%; 
    height: 380px; 
    border-radius:21px 21px 0 0;
}

.contenedorFooterGlobal{ background-color: #090F31; }
.contenedorFooterGlobal a{ color: rgb(186, 186, 186) !important;}
.contenedorFooterGlobal p{ color: rgb(186, 186, 186) !important;}
.contenedorFooterGlobal i{ color: rgb(186, 186, 186) !important;}
/* PÁGINA HOME /////////////////////////////////////////////////////////////////////// */



/* CONTENEDORES ///////////////////////////////////////////////////////////////////// */
.contenedorUno{
    background-color: black;
}
/* CONTENEDORES ///////////////////////////////////////////////////////////////////// */



/* CONTENEDOR CARRUSEL IMAGENES COMPRAR ////////////////////////////////////////////// */
#carouselCompra .carousel-item {
    height: 100% !important;
}




/* UNIDAD 15 */
.tour360sanvicente{ width:100%; }
#contenedorTipologias{margin-bottom: 100px !important;}
.imagenesTipologias{width: 100% !important;}



.videoEnvVerVideos{
    width: 100% !important;
    height: 170px;
}




.claseVideoHome {
    width: 100%;
    height: 700px; /* Puedes ajustar la altura según sea necesario */
    margin-bottom: 20px; /* Espacio entre los videos */
}

/* PAGINA VER VIDEOS CAMAROGRAFO ///////////////////// */
.claseVideosPartido {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* Relación de aspecto 16:9 */
    position: relative;
}

.claseVideosPartido iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* .video-js {
    width: 500px !important;
    height: 1280px !important;
} */


.contenedor-video-grabacion {
    position: relative;
    width: 100%;
  }
  
  .overlay-play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none; /* Esto permite que los clics pasen al enlace */
  }
  
  .iconoReproducir {
   font-size: 80px;
   color: white;
  }
/* PAGINA VER VIDEOS CAMAROGRAFO ///////////////////// */




.videoCaptura{
    width: 100%;
    height: 480px; /* Puedes ajustar la altura según sea necesario */
    
}


/* CLASES DE CAMAROGRAFO */
.botonNomal, .botonSelect {
    background-color: rgba(255, 255, 255, 0.7);
    padding: 10px 0px 10px 0px;
    border-radius: 5px;
    margin: 0px 0px 0px 0px;
}
.capturaVideo {
    width: 100%;
    height: 100%;
}
.botonesVideo{
    margin: 0px 0px 0px 0px;
}
/* clases animación mientras espera que cargue el video */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}
 
.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loadingText {
    color: white;
    margin-top: 20px;
    font-size: 18px;
}


.inputBusquedaHome, .inputFiltroHome{
    width: 100%;
    
}

.textosDescripcionDos{
    font-size: 13px !important;
}

.textosDescripcionUno{
    font-size: 17px !important;
}

.textosDescripcionTorneoAnalitica{
    font-size: 20px !important;
}

.bordeAnaliticas{
    border: 2px solid #0E1465;
    border-radius: 10px;
}


.videoIndividual{
    width: 100% !important;
    height: 630px;
}


.tituloVideoIndividual{
    display: none;
}

/* Grabarr */
.imagenEquipos{
    width: 90px;
}


.contenedorTorneosGlobal{
    margin-top: 150px;
    margin-bottom: 150px;
}

.contenedorTorneosGlobalHome{
    margin-top: 50px;
    margin-bottom: 50px;
}


/* etiquetas */
.etiquetas-container {
    display: flex;
    flex-wrap: wrap;  /* Esto permite que las etiquetas se ajusten si hay muchas */
    justify-content: center;  /* Centra las etiquetas horizontalmente */
    gap: 10px;  /* Espaciado entre las etiquetas */
}

.etiqueta-item {
    background-color: #f0f0f0;  /* añadir un fondo para hacerlas más visibles */
    padding: 5px 10px;  /* Espacio dentro de la etiqueta */
    border-radius: 15px;  /* Bordes redondeados */
    font-size: 14px;  /* Tamaño de texto */
    display: inline-block;
}



.paga-button {
    position: fixed; /* Posición fija en la ventana */
    bottom: 20px; /* Distancia desde la parte inferior */
    right: 20px; /* Distancia desde la derecha */
    z-index: 1000; /* Asegúrate de que el botón esté encima de otros elementos */
}

.paga-button img {
    width: 140px; /* Ajusta el tamaño del icono */
    transition: transform 0.3s; /* Transición para el efecto de hover */
}

.paga-button img:hover {
    transform: scale(1.1); /* Efecto de aumento al pasar el cursor */
}


/* mercadopago imagen */
.mpImagen{
    width: 300px; 
    border-radius: 50%;
    border: 5px solid #000;
    object-fit: cover;
}

/* Todos Torneos:  */
/* Contenedor principal */
.contenedor-torneos-global {
   color: white;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
  }
  

/* Este código elimina el padding y el margen del container en pantallas pequeñas */
@media (max-width: 767px) {
    .contenedor-no-en-xs {
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
      width: auto;
    }
    .contenedor-torneos-global{
        padding: 15px;
        margin: 15px;
    }
  }
  
  
  /* Estilo del título */
  .titulo-torneos {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 2px solid #ddd; /* Border bottom */
    padding-bottom: 5px;
  }
  
  /* Tarjeta del torneo */
  .torneo-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  /* Imagen del torneo */
  .torneo-imagen {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    object-fit: cover;
  }
  
  /* Información del torneo */
  .torneo-nombre {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    color: white;
  }
  
  .torneo-descripcion {
    font-size: 0.9rem;
    color: white;
    margin: 0;
  }
  

/*  */
/* footer nuevo:  */
.nav-item-01{
    text-decoration: none;
}
.no-underline {
    text-decoration: none;
  }
.nav-item-01:not(:last-child)::after {
    content: '|';
    padding-left: 10px;
    padding-right: 10px;
    color: white;
  }
  
/*  */

/* Contenedor partidos: */
/* Estilo del contenedor padre */
/* Contenedor padre */
.contenedor-padre-01 {
    
    padding: 1.5rem;
    background-color: transparent;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  /* Título */
  .titulo-partidos-01 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    border-bottom: 2px solid #ccc;
    padding-bottom: 0.5rem;
    color: white;
  }
  
  /* Lista de partidos */
  .partidos-lista-01 {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  /* Estilo de cada partido */
  .partido-01 {
    display: grid;
    grid-template-columns: 1fr auto 1fr; /* Tres columnas */
    align-items: center; /* Alinea verticalmente */
    gap: 1rem; /* Espaciado entre columnas */
    border: 1px solid #ddd;
    padding: 1rem;
    border-radius: 8px;
    background-color: transparent; /* Fondo transparente */
  }
  
  /* Equipo uno: disposición normal */
  .equipo-01 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
  }

  
  /* Texto "VS": siempre centrado */
  .vs-texto-01 {
    margin: 0px 0px 0px 0px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #666;
    text-align: center; /* Centra horizontalmente */
  }

  .textosBajovs{
    text-align: center; /* Centra horizontalmente */
    font-size: 11px;
    align-items: center;
    color: white;
    margin: 0px 0px 0px 0px;
  }
  
  /* Equipo dos: imagen a la derecha y texto a la izquierda */
  .equipo-02 {
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Empuja todo el contenido hacia la derecha */
    gap: 0.5rem; /* Espacio entre imagen y texto */
    color: white;
  }
  
  /* Imagenes de los equipos */
  .imagen-equipo-01,
  .imagen-equipo-02 {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #ddd;
  }
  
  /* Nombres de los equipos */
  .nombre-equipo-01,
  .nombre-equipo-02 {
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0px 0px 0px 0px;
  }
  @media (max-width: 767px) {
    .contenedor-no-en-xs {
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
      width: auto;
    }
    .contenedor-padre-01{
        padding: 15px;
        margin: 15px;
    }
    .partidos-lista-01{
        margin: 0px 10px;
    }
  }
  
/*  */