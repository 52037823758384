.sticky-header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #090F31;
  z-index: 1040; /* Ajusta el z-index aquí si es necesario */
}

.modal {
  z-index: 1051; /* Asegúrate de que el modal esté por encima del sticky-header */
}
.modal-dialog {
  z-index: 1051; /* Un valor más alto para asegurarse de que se muestre correctamente */
}

/* PREDETERMINADAS DE BOOTSTRAP ////////////////////////////////////////////////////////////////// */
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
  --bd-violet-bg: #712cf9;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6528e0;
  --bs-btn-hover-border-color: #6528e0;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
}
.bd-mode-toggle {
  z-index: 1500;
}
/* PREDETERMINADAS DE BOOTSTRAP ////////////////////////////////////////////////////////////////// */





/* ADAPTABILIDAD ///////////////////////////////////////////////// */
/* antes de 990px */
@media only screen and (max-width: 990px) {



  #contenedorSegundaParteHeader{ width: 100% !important;}  
  
  /* se colocó para poder centrar los titulos en el header global */
  .barra{
    margin-left: 10%;
  }


  #miSidebar{ display: none !important; }


  /* Footer global /////// */
  .copyright{ text-align: center !important;}
  .redes{ text-align: center !important;}
  /* Footer global /////// */
}
/* ADAPTABILIDAD ///////////////////////////////////////////////// */


/* HEADER GLOBAL /////////////////////////////////////////////// */
.barra{
  
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-link-color);
    --bs-nav-link-hover-color: var(--bs-link-hover-color);
    --bs-nav-link-disabled-color: var(--bs-secondary-color);
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

/* header: */
/* Clase para centrar la imagen y los textos */
.barra {
  display: flex;
  align-items: center;
  
}

/* Imagen de la navbar */
.logo-navbar {
  height: 80px; /* Tamaño reducido */
}

/* Ajuste de tamaño del texto */
.text-small {
  font-size: 0.875rem; /* Más pequeño que el tamaño base */
}

/* Espaciado para que los íconos y textos se alineen bien */
.nav-item .nav-link,
.nav-item .filtrar-texto {
  line-height: 1.5; /* Ajusta la altura de línea */
}


/* Clase para TORNEOS con color azul cielo */
.nav-torneos {
  color: white; /* Código del color azul cielo */
  font-weight: 300;
}
/* Clase para el ícono */
.nav-icon {
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
  margin-left: 1rem;
}

/* Contenedor de filtros oculto por defecto */
.contenedor-filtros.oculto {
  display: none;
}

/* Contenedor de filtros visible */
.contenedor-filtros {
  display: block;
  background-color: #090F31;
  padding: 1rem;
  border: 1px solid #090F31;
  border-radius: 5px;
  margin-top: 1rem;
}
/* Contenedor de filtros debajo del header */
/* Contenedor de filtros global */
.contenedor-filtros-global {
  background-color: #090F31;
  padding-top: 10px;
  padding-bottom:25px ;
  border-top: 1px solid #090F31;
  position: relative; /* Permite que esté bajo el header */
  width: 100%;
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  z-index: 1050; /* O un valor suficientemente alto */
  pointer-events: auto; /* Permite hacer clic en el contenedor */

}

/* Transición suave al aparecer */
.contenedor-filtros-global {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}





/* Estilo para el botón "Iniciar Sesión" */
.btn-iniciar-sesion {
  background-color: #0E1465; /* Fondo blanco */
  color: white; /* Texto negro */
  border: 2px solid #0E1465; /* Borde negro */
  border-radius: 25px; /* Bordes redondeados */
  font-weight: lighter; /* Texto en negrita */
  padding: 10px 10px; /* Espaciado interno */
  transition: all 0.3s ease-in-out; /* Animación suave */
  font-size: 12px; /* Más pequeño que el tamaño base */

}

.btn-iniciar-sesion:hover {
  background-color: white; /* Fondo negro */
  color: #090F31; /* Texto blanco */
}

/* Estilo para el botón "Registrar" */
.btn-registrar {
  background-color: white; /* Azul predeterminado */
  color: #0E1465; /* Texto blanco */
  border: 2px solid white; /* Borde azul */
  border-radius: 35px; /* Bordes redondeados */
  font-weight: lighter; /* Texto en negrita */
  padding: 10px 10px; /* Espaciado interno */
  transition: all 0.3s ease-in-out; /* Animación suave */
  font-size: 12px; /* Más pequeño que el tamaño base */

}

.btn-registrar:hover {
  background-color: #0E1465; /* Azul más oscuro al pasar el mouse */
  border-color: #0E1465; /* Ajustar el color del borde */
  color: white;
}
/* Personalización del fondo del backdrop */
.modal-backdrop.modal-123 {
  background-color: rgba(0, 0, 0, 0.6) !important; /* Fondo oscuro */
}

/* Personalización del modal */
.modal-content-123 {
  border-radius: 10px; /* Bordes redondeados */
  z-index: 1060; /* Asegúrate de que el contenido del modal tenga un z-index adecuado */
}

/* Fondo del modal más oscuro */
.modal-backdrop {
  background-color: black;
}
.modal-dialog {
  pointer-events: auto !important; /* Permite hacer clic en el contenedor */

}

/* Ajuste de tamaño de la imagen en pantallas pequeñas */
@media (max-width: 576px) {
  .small-logo {
    width: 80px; /* Ajusta el tamaño de la imagen */
    height: auto; /* Mantén la proporción */
    display: block;
    margin-left: auto;
    margin-right: auto; /* Centra la imagen */
  }
  .pantallasp{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .btn-menu-offcanvas {
    border: 2px solid white; /* Borde blanco alrededor */
    padding: 5px 10px;  }

  .btn-menu-offcanvas i {
    color: white; /* Asegura que el ícono sea visible */
    font-size: 1.2rem; /* Ajusta el tamaño del ícono */
  }
}

/* Asegura que los elementos estén alineados en una fila y sin espacios adicionales */
@media (max-width: 576px) {
  .barra {
    display: flex; /* Asegura que los elementos estén en una fila */
    justify-content: center; /* Centra el contenido en pantallas pequeñas */
    gap: 0; /* Elimina el gap entre los elementos */
    margin: 0; /* Elimina márgenes innecesarios */
    padding: 0; /* Elimina padding innecesario */
  }
}

/* Estilo para el menú offcanvas con borde blanco */
.offcanvas {
  border-left: 3px solid white; /* Borde blanco en el lado izquierdo */
}



#miNavBarGlobal{ background-color: #0E1465 !important; }

.dropdown-divider {
  height: 1px;
  margin: 0.5rem 0;
  overflow: hidden;
  background-color: #e9ecef;
}

.bg-blue {
  background-color: #0E1465;
  color: white; 
}

.bg-blue:hover {
  background-color: black; 
  color: white;
}




.headerFinal{
  display: flex;
}

.mi-drowpdown:hover{
  background-color: #0E1465;
  color: white; 
}
/* HEADER GLOBAL /////////////////////////////////////////////// */





/* FOOTER USUARIO ADMIN ////////////////////////// */
#myFooterUsuarioAdmin{ background-color: #060a09; }

.parrafoFooter{
  font-size: 13px !important;
  margin-bottom: 0px !important;
  color: #ffffff;
}
/* FOOTER USUARIO ADMIN ////////////////////////// */




/* HEADER USUARIO ////////////////////////////////// */
#miNavBar{
  background-color: #0E1465 !important;
  box-shadow: 1px 1px 7px rgb(16, 16, 16);
}
/* HEADER USUARIO ////////////////////////////////// */




#miSidebar{ background-color: #3A58A5 !important;  }



.swiper-slide {
  display: flex;
  justify-content: center; /* Centra los videos dentro de cada slide */
}

.swiper-slide video {
  max-height: 300px; /* Ajusta según tu diseño */
  object-fit: cover;
}


/* editar patrocinador */
.select-alto {
  height: 100px !important; /* Ajusta la altura deseada */
  overflow-y: auto; /* Muestra barra de desplazamiento si es necesario */
}