/* PREDETERMINADAS DE BOOTSTRAP ////////////////////////////////////////////////////////////////// */
.containerUsuario {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Asegura que el contenedor ocupe toda la altura de la ventana */
  
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/public/imagenes/webGlobal/fondoUsuario.jpg');  /* con este atributo podemos colocar una imagen opacada */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.containerRegistro {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Asegura que el contenedor ocupe toda la altura de la ventana */
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/public/imagenes/webGlobal/fondoRegistro.jpg'); /* con este atributo podemos colocar una imagen opacada */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.containerCamarografo {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Asegura que el contenedor ocupe toda la altura de la ventana */
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/public/imagenes/webGlobal/fondoCamara.jpg'); /* con este atributo podemos colocar una imagen opacada */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.containerAdmin {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Asegura que el contenedor ocupe toda la altura de la ventana */
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/public/imagenes/webGlobal/fondoAdmin.jpg'); /* con este atributo podemos colocar una imagen opacada */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.containerRecuperar {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Asegura que el contenedor ocupe toda la altura de la ventana */
  
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/public/imagenes/webGlobal/fondoRecuperar.jpg');  /* con este atributo podemos colocar una imagen opacada */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.containerAlerta{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ocupa toda la altura de la ventana */
  background-color: #ffd900; /* Color de fondo opcional */
  text-align: center; /* Centra el texto dentro del contenedor */
}

.containerAletarMercadoPago{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ocupa toda la altura de la ventana */
  text-align: center; /* Centra el texto dentro del contenedor */
}

.containerAlertaDos{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ocupa toda la altura de la ventana */
  background-color: #0E1465; /* Color de fondo opcional */
  text-align: center; /* Centra el texto dentro del contenedor */
  padding: 0px 20px 0px 20px;
}

.form-signin {
  width: 400px;
  padding: 1rem;
} 

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="text"] {
  margin-top: -1px;
  margin-bottom: -1px;
  border-radius: 0px 0px 0px 0px;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bd-placeholder-img {
font-size: 1.125rem;
text-anchor: middle;
-webkit-user-select: none;
-moz-user-select: none;
user-select: none;
}

@media (min-width: 768px) {
.bd-placeholder-img-lg {
  font-size: 3.5rem;
}
}

.b-example-divider {
width: 100%;
height: 3rem;
background-color: rgba(0, 0, 0, .1);
border: solid rgba(0, 0, 0, .15);
border-width: 1px 0;
box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
flex-shrink: 0;
width: 1.5rem;
height: 100vh;
}

.bi {
vertical-align: -.125em;
fill: currentColor;
}

.nav-scroller {
position: relative;
z-index: 2;
height: 2.75rem;
overflow-y: hidden;
}

.nav-scroller .nav {
display: flex;
flex-wrap: nowrap;
padding-bottom: 1rem;
margin-top: -1px;
overflow-x: auto;
text-align: center;
white-space: nowrap;
-webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
--bd-violet-bg: #712cf9;
--bd-violet-rgb: 112.520718, 44.062154, 249.437846;

--bs-btn-font-weight: 600;
--bs-btn-color: var(--bs-white);
--bs-btn-bg: var(--bd-violet-bg);
--bs-btn-border-color: var(--bd-violet-bg);
--bs-btn-hover-color: var(--bs-white);
--bs-btn-hover-bg: #6528e0;
--bs-btn-hover-border-color: #6528e0;
--bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
--bs-btn-active-color: var(--bs-btn-hover-color);
--bs-btn-active-bg: #5a23c8;
--bs-btn-active-border-color: #5a23c8;
}
.bd-mode-toggle {
z-index: 1500;
}
/* PREDETERMINADAS DE BOOTSTRAP ////////////////////////////////////////////////////////////////// */


/* Mis clases */
.enlaces{
  font-size: 14px !important;
}

