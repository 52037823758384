/* Contenedor fijo para la columna izquierda */
.sticky-container-01 {
    position: relative;
    max-width: 100%; /* Asegura que no exceda el ancho disponible */
}

.sticky-content-01 {
    position: sticky;
    top: 0;
    z-index: 10;
}

/* Imagen responsiva con clase numerada */
.img-fluid-01, .img-fluid-010 {
    width: 50%; /* Ajusta el tamaño de las imágenes */
    height: auto;
    max-width: 100%; /* No excede el contenedor */
    display: block; /* Asegura el comportamiento adecuado */
    margin: 0 auto; /* Centra la imagen horizontalmente */
}
.img-fluid-full-01{
    width: 100%; /* Ajusta el tamaño de las imágenes */
    height: auto;
    max-width: 100%; /* No excede el contenedor */
    display: block; /* Asegura el comportamiento adecuado */
    margin: 0 auto; /* Centra la imagen horizontalmente */
}

/* Contenedor personalizado para el enlace */
.link-container-01 {
    display: flex;
    flex-direction: column; /* Alinea los elementos en columna */
    justify-content: center; /* Centra verticalmente */
    align-items: center; /* Centra horizontalmente */
    text-align: center; /* Centra el texto si lo hubiera */
    max-width: 100%; /* No excede el ancho del contenedor */
}


/* Media Queries para pantallas pequeñas (iPhone SE y tamaños similares) */
@media (max-width: 576px) {
    /* Clase personalizada para ocultar la columna en pantallas pequeñas */
    
    /* Apilar las columnas (col-4 y col-8) en una fila en dispositivos pequeños */
    .d-flex.pantallas-pequenas {
        display: flex;
        flex-direction: column !important; /* Apila las columnas en una fila */
    }

    /* Hacer que ambas columnas ocupen el 100% del ancho */
    .col-4.pantallas-pequenas,
    .col-8.pantallas-pequenas {
        width: 100% !important; /* Ambas columnas ocupan el 100% del ancho */
    }

    /* Cambiar el orden: Columna del patrocinador primero */
    .col-4.pantallas-pequenas {
        order: 1; /* Coloca la columna de patrocinador primero */
    }

    .col-8.pantallas-pequenas {
        order: 2; /* Coloca la columna de video después */
    }
}

/* Media Queries para pantallas medianas (iPhone XR) */
@media (min-width: 577px) and (max-width: 768px) {
    /* Ajustar la distribución a fila para pantallas medianas */
    .d-flex.pantallas-pequenas {
        flex-direction: row !important; /* Alineación en fila */
    }

    /* Ajustar el tamaño de las columnas */
    .col-4.pantallas-pequenas {
        width: 40% !important;
    }

    .col-8.pantallas-pequenas {
        width: 60% !important;
    }
}


/* Mostrar solo en pantallas pequeñas */


@media (max-width: 576px) {
    .mostrar-en-pequenas {
        display: block !important; /* Mostrar en pantallas pequeñas */
    }
}

/* Ocultar solo en pantallas pequeñas */
.ocultar-en-pequenas {
    display: block; /* Mostrar por defecto */
}

@media (max-width: 576px) {
    .ocultar-en-pequenas {
        display: none !important; /* Ocultar en pantallas pequeñas */
    }
}
