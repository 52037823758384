/* ADAPTABILIDAD ///////////////////////////////////////////////// */
/* antes de 990px */
@media only screen and (max-width: 990px) {
  .videoVoz{ height: 100px !important; }

  .zoomButton {
    padding: 7px 12px 7px 12px !important;
    font-size: 10px !important;
  }
  
  .zoomButtons {
    gap: 5px !important; /* Espacio entre los botones */
  }
  .volverRotar {
    gap: 5px !important; /* Espacio entre los botones */
  }
  
  .iconoVolver{
    width: 40px !important; /* Ajusta según el tamaño deseado */
    height: 40px !important; /* Ajusta según el tamaño deseado */
  }

  .iconoRotar{
    width: 50px !important; /* Ajusta según el tamaño deseado */
  }
}



.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.capturaVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.botonesVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  pointer-events: none; /* Para que los botones no interfieran con la reproducción del video */
}

.botonesVideo img {
  pointer-events: auto; /* Para permitir la interacción con los botones */
  cursor: pointer;
  margin: 5px;
}

/* Estilos específicos para pantalla completa usando media query */
@media all and (display-mode: fullscreen) {
  .botonesVideo {
    display: flex;
    justify-content: space-between;
    z-index: 10;
  }

  .botonesVideo img {
    pointer-events: auto;
    cursor: pointer;
    margin: 5px;
  }
}

.columnas {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.columnaIzquierda,
.columnaDerecha {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: auto; /* Para permitir la interacción con los botones */
}
.columnaCentral{
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: column;
  pointer-events: auto; /* Para permitir la interacción con los botones */
}

.icono {
  width: 80px; /* Ajusta según el tamaño deseado */
  height: 80px; /* Ajusta según el tamaño deseado */
  cursor: pointer;
}

.iconoVolver {
  width: 50px; /* Ajusta según el tamaño deseado */
  height: 50px; /* Ajusta según el tamaño deseado */
  cursor: pointer;
}

.iconoRotar {
  width: 60px; /* Ajusta según el tamaño deseado */
  cursor: pointer;
}

.fullScreenButton {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.seleccionado {
  border: 2px solid blue; /* Ajusta según tu preferencia */
  border-radius: 5px; /* Ajusta según tu preferencia */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.loadingText {
  color: white;
  margin-top: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.zoomIn {
  transform: scale(2); /* Cambia este valor según sea necesario */
  transform-origin: center;
}

.zoomOut {
  transform: scale(1); /* Valor por defecto */
  transform-origin: center;
}


.hidden {
  display: none;
}


.videoVoz{ height: 500px; }


.zoomButton {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
  margin: 0 5px;
  cursor: pointer !important;
  font-size: 16px;
}

.zoomButton:hover {
  background-color: #f0f0f0;
}

.zoomButtons {
  display: flex; /* Alinea los botones de zoom en fila */
  gap: 10px; /* Espacio entre los botones */
}

.volverRotar{
  display: flex; /* Alinea los botones de zoom en fila */
  gap: 10px; /* Espacio entre los botones */
  margin: 20px 0px 100px 0px;
}

.textoNombresEquipos{
  font-size: 16px;
  color: white;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1); /* Sombra negra, desplazada y sin desenfoque */
}